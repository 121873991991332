/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import PostCard from "../components/post-card"
import Seo from "../components/seo"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"

export const pageQuery = graphql`
  query GCCerQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        tagline
        featuredImage {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, height: 500)
          }
        }
        featuredImage2 {
          publicURL
        }
        featuredImage3 {
          publicURL
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    posts: allMarkdownRemark(
      # limit: 16
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { template: { regex: "/ceramics$/" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            thumbnailImage {
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 345, height: 260)
              }
            }
          }
        }
      }
    }
  }
`
const CCeramics = ({ data }) => {
  const { markdownRemark, posts } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""

  const settings = {
    className: "center",
    dots: false,
    centerMode: true,
    infinite: true,
    centerPadding: "75px",
    speed: 500,
    slidesToShow: posts.edges.length > 4 ? 4 : posts.edges.length,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  }

  // function that traverses the array of posts and returns the index of current post
  const findCurrentPostIndex = () => {
    let index = 0
    posts.edges.forEach((post, i) => {
      if (post.node.frontmatter.title === frontmatter.title) {
        index = i
      }
    })
    return index
  }

  // function that returns the next post
  const findNextPost = () => {
    let index = findCurrentPostIndex()
    if (index === posts.edges.length - 1) {
      return posts.edges[0].node.frontmatter
    } else {
      return posts.edges[index + 1].node.frontmatter
    }
  }

  // function that returns the previous post
  const findPrevPost = () => {
    let index = findCurrentPostIndex()
    if (index === 0) {
      return posts.edges[posts.edges.length - 1].node.frontmatter
    } else {
      return posts.edges[index - 1].node.frontmatter
    }
  }

  return (
    <Layout className="container page">
      <Seo title={frontmatter.title} description={excerpt} />
      {/* <a href={findPrevPost()?.slug} class="left_arrow_page">
        <RiArrowLeftLine size={50} />
      </a>
      <a href={findNextPost()?.slug} class="right_arrow_page">
        <RiArrowRightLine size={50} />
      </a> */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h1 className="title about-navigation" style={{ paddingTop: "60px" }}>
          <a href="/ceramics">{"Ceramics  > "}</a> {frontmatter.title}
        </h1>
        <div style={{ paddingTop: "60px", minWidth: "58px" }}>
          <h2>
            <a href={findPrevPost()?.slug}>{"< "}</a>
            {" | "}
            <a href={findNextPost()?.slug}>{" >"}</a>
          </h2>
        </div>
      </div>
      <div className="home-banner-2 grids col-1 sm-2">
        <div>
          {!frontmatter.featuredImage2 ? (
            <div style={{ justifyContent: "center", display: "flex" }}>
              <Zoom>
                <img
                  src={frontmatter.featuredImage.publicURL}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                    maxHeight: "500px",
                  }}
                />
              </Zoom>
            </div>
          ) : !frontmatter.featuredImage3 ? (
            <Carousel>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Zoom>
                  <img
                    src={frontmatter.featuredImage.publicURL}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "500px",
                    }}
                  />
                </Zoom>
              </div>

              <div style={{ justifyContent: "center", display: "flex" }}>
                <Zoom>
                  <img
                    src={frontmatter.featuredImage2.publicURL}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "500px",
                    }}
                  />
                </Zoom>
              </div>
            </Carousel>
          ) : (
            <Carousel>
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Zoom>
                  <img
                    src={frontmatter.featuredImage.publicURL}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "500px",
                    }}
                  />
                </Zoom>
              </div>

              <div style={{ justifyContent: "center", display: "flex" }}>
                <Zoom>
                  <img
                    src={frontmatter.featuredImage2.publicURL}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "500px",
                    }}
                  />
                </Zoom>
              </div>

              <div style={{ justifyContent: "center", display: "flex" }}>
                <Zoom>
                  <img
                    src={frontmatter.featuredImage3.publicURL}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "500px",
                    }}
                  />
                </Zoom>
              </div>
            </Carousel>
          )}
        </div>
        <div>
          <p
            className="tagline"
            sx={{
              color: "muted",
            }}
          >
            {frontmatter.tagline}
          </p>
          <div
            className="description"
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
      <div>
        {/* <h2></h2> */}
        <Slider {...settings}>
          {posts.edges.map(elem => (
            <div>
              <a href={elem.node.frontmatter.slug}>
                <img
                  src={elem.node.frontmatter.thumbnailImage?.publicURL}
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "contain",
                  }}
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </Layout>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
      }}
      onClick={onClick}
    >
      {"<"}
    </div>
  )
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
      }}
      onClick={onClick}
    />
  )
}

export default CCeramics
